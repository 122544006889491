import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgQuizCover from "../../assets/images/gen-mars/quiz-result/cover.png"

const quizResultPage = () => (
  <Layout>
    <SEO title="Quiz Review" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport>
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgQuizCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay flex justify-center items-center">
            <div className="mt6">
            <h2 className="relative uk-text-center">
                Mars Quiz Result
                <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
            </h2>
            <div className="pa3 mt5 uk-text-center ba b-white" style={{backgroundColor: "rgba(0,0,0,.52)", backdropFilter: "saturate(180%) blur(5px)", WebkitBackdropFilter: "saturate(180%) blur(5px)", maxWidth: "400px"}}>
                <div className="font-nostromo uk-text-bold text-3d" style={{fontSize: "8rem", color: "#fff", WebkitTextStroke: "2px #000"}}>
                    98<sup>%</sup>
                </div>
                <p className="text-light pb0 mb0">
                    You've Got
                </p>
                <div className="uk-text-large uk-text-bold text-highlight-brown">
                    <span>16 </span><span> / </span> <span>20</span> Correct
                </div>
                <p className="text-light uk-text-small">
                You have performed better than <span>70</span>% who have taken the Quiz.
                </p>
            </div>
            <div className="uk-background-secondary flex justify-between">
                <button className="uk-button uk-button-secondary uk-button-large" style={{padding: "0 10px"}}>
                    TRY AGAIN
                </button>
                <button className="uk-button uk-button-secondary uk-button-large" style={{padding: "0 10px"}}>
                    REVIEW ANSWERS
                </button>
                <button className="uk-button uk-button-secondary uk-button-large" style={{padding: "0 10px"}}>
                <span uk-icon="icon: social"></span>  SHARE
                </button>
            </div>
            </div>
            
        </div>
      </div>
    </main>
  </Layout>
)

export default quizResultPage
